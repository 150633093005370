import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StyledButton } from "./../../styles/StyledComponents/StyledButton";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const query = graphql`
  query {
    cover: file(relativePath: { eq: "eksplozijaEmocij.jpg" }) {
      image: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AlbumDownload = () => {
 const links = [
   {
     text: "SPOTIFY",
     url: "https://open.spotify.com/album/3sob4qUJQLFFKXofKixWzx",
   },
   {
     text: "ITUNES",
     url: "https://music.apple.com/us/album/eksplozija-emocij/1675152897",
   },
   {
     text: "TIDAL",
     url: "https://tidal.com/browse/album/279404746",
   },
   {
     text: "YOUTUBE",
     url:
       "https://m.youtube.com/playlist?list=OLAK5uy_mH-XJr9Zpq_gI7GAXyy-66wcxV65B3_kA&feature=shares",
   },
   {
     text: "BANDCAMP",
     url: "https://2bband.bandcamp.com/album/eksplozija-emocij",
   },
   {
     text: "SOUNDCLOUD",
     url:
       "https://soundcloud.com/glasbena-skupina-2b/sets/eksplozija-emocij-album",
   },
 ];


  const { cover } = useStaticQuery(query);

  return (
    <Wrapper>
      <TitleText>
        <h1>EKSPLOZIJA EMOCIJ</h1>
      </TitleText>
      <StyledImage>
        <Img
          fluid={cover.image.fluid}
          className="img"
          imgStyle={{ objectFit: "contain" }}
        />
      </StyledImage>{" "}
      <ProductContainer>
        <a
          href="https://www.dropbox.com/sh/e1698ykpsqic5zm/AADPLoz1i-1FbXudr_phAC0Za/mp3?dl=0&subfolder_nav_tracking=1"
          target="_blank"
        >
          <h2>SNEMI SI ALBUM!</h2>
        </a>
      </ProductContainer>
      <LinkList>
        {links.map(link => (
          <StyledLink
            key={link.text}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2> {link.text}</h2>
          </StyledLink>
        ))}
      </LinkList>
    </Wrapper>
  );
};

export default AlbumDownload;

const TitleText = styled.div``;
const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  color: ${({ theme }) => theme.gold[500]};
  margin-bottom: 4rem;
  h2 {
    margin-top: 2rem;
  }
`;

const StyledImage = styled.div`
  height: auto;
  width: 90%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 800px;
  }
`;
const Wrapper = styled.section`
 width: 90vw;
  margin: 8rem auto;
  text-align: center;

  a {
    text-decoration: none;
  }

  h2 {
    color: ${({ theme }) => theme.gold[500]};
  }

 ${TitleText} {
      color: ${({ theme }) => theme.gold[500]};
      margin-bottom: 2rem;
    h2 {
      /* color: ${({ theme }) => theme.gold}; */
      letter-spacing: ${({ theme }) => theme.letterSpacing};
      font-weight: 500;
      letter-spacing: 2px;
    }

    p {
     color: ${({ theme }) => theme.white};
      text-align: left;
      padding: 1rem 0 3rem;
    }
  }

   p {
   color: ${({ theme }) => theme.gold[500]};
  }

    .link {
    color: ${({ theme }) => theme.gold[500]};

    &:hover {
      opacity: 0.7;
    }
  }


  /* == DESKTOP == */
  @media (min-width: 1200px) {
    height: 100%;
    .content {
      width: 80vw;
    }

  }

  @media (min-width: 1440px) {
    .content {
      width: 60vw;
    }
  }
`;

const LinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.gold[500]};
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;
