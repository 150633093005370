import React from "react";
import AlbumDownload from './../components/AlbumDownload/AlbumDownload';
import Layout from "./../layout/layout";
import SEO from "./../layout/SEO";


export default () => {
  return (
    <Layout>
      <SEO title="AlbumDownload" />
      <AlbumDownload />
    </Layout>
  );
};
